<template>
    <div class="center-frm">
        <template>
            <div v-for="(item,idx) in menus" :key="idx">
                <div class="title">{{item.title}}</div>
                <div class="content">
                    <div v-for="(item1,idx1) in item.children" :key="idx1">
                        <router-link :to="item1.key" class="icon-block">
                            <span class="icon iconfont icon-OvalPathmengban"></span>
                            <div class="title">{{item1.title}}</div>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <div>
                <div class="title">权限管理</div>
                <div class="content">
                    <router-link to="/purview/manager" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">管理员管理</div>
                    </router-link>
                    <router-link to="/purview/role" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">角色管理</div>
                    </router-link>
                    <router-link to="/purview/htUser" class="icon-block">
                        <span class="icon iconfont icon-qianyue-sjcs"></span>
                        <div class="title">后台用户管理</div>
                    </router-link>
                </div>
            </div> -->
        </template>
    </div>
</template>

<script>
    import {navs} from '../layout/menu';
    import { navBuildNav } from '@/service/account';
    export default {
        components: {

        },
        data() {
            return {
                menus: null,
            }
        },
        created() {
            this.leftMenu();
        },
        methods: {
            leftMenu() {
                // this.menus = this.navs;
                let leftMenus = [];
                navBuildNav().then(rst => {
                    rst.forEach((e,idx) => {
                        let nav = {
                            title: e.name,key: "purview"+e.id,
                            icon:'icon iconfont icon-hetongguanli-',
                            children:[],
                        };
                        if (e) {
                            leftMenus.push(nav);
                            e.children.forEach((n,idx1)=>{
                                let nav1 = {
                                    title: n.name,key: n.url,
                                    icon:'icon iconfont icon-hetongguanli-',
                                };
                                leftMenus[idx].children.push(nav1);
                            })
                        }
                    });
                    this.menus = leftMenus;
                    // console.log(rst);
                    console.log(this.menus);
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .center-frm {
        color: rgba(41, 41, 41, 1);
        height: 100%;
        overflow: auto;
    }

    .center-frm>div {
        width: 75%;
        max-width: 1200px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(192, 179, 179, 0.5);
        border-radius: 10px 10px 10px 0px;
        margin: 20px auto 20px;
    }

    .center-frm>div>.title {
        height: 75px;
        line-height: 75px;
        font-weight: 600;
        color: rgba(59, 59, 59, 1);
        font-size: 18px;
        padding-left: 30px;
        border-bottom: 2px solid rgba(230, 230, 230, 1);
    }

    .content {
        padding: 40px;
        text-align: center;
        overflow: hidden;
    }
    .content span.iconfont.active {
        color: #ccc;
    }
    .sub-title{
        padding: 20px 30px;
        font-weight: bold;
        border-bottom: 1px solid #eee;
    }
    .icon-block {
        float: left;
        padding: 30px;
        cursor: pointer;
    }

    .icon-block .icon {
        font-size: 38px;
    }

    .icon-block .title {
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
        font-size: 16px;
    }
</style>